import style from '../styles/menu.module.scss';
import React from 'react';
import { Link } from 'gatsby';

const Button = ({ title }: { title: string }) => {
  return (
    <li>
      <Link to="/{title}">{title}</Link>
    </li>
  );
};

const Menu = () => {
  return (
    <>
      <div className={style.desktopMenuContainer}>
        <ul className={style.menu}>
          <Button title="About"></Button>
          <Button title="Blog"></Button>
        </ul>
      </div>
    </>
  );
};

export default Menu;
