import Bio from '../components/bio';
import Layout from '../components/layout';
import React from 'react';

const SampleIndex = () => {
  return (
    <Layout>
      <h1>Hello, World!</h1>
    </Layout>
  );
};
export default SampleIndex;
