import Header from './header';
import Footer from './footer';
import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import '../styles/layout.scss';

const Layout = ({ children }: { children: ReactNode }) => {
  return (
    <div className="container">
      <Header />
      <div className="content">{children}</div>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
