import style from '../styles/footer.module.scss';
import React from 'react';

const Footer = () => (
  <footer className={style.footer}>
    <>
      <span className="footerCopyrights">
        © 2019 Built with <a href="https://www.gatsbyjs.org">Gatsby</a>
      </span>
      <span className="footerCopyrights">
        Starter created by <a href="https://radoslawkoziel.pl">panr</a>
      </span>
    </>
  </footer>
);
export default Footer;
